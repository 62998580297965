import axios from "@/utils/request";

// 获取热门视频
export function queryVidList(data) {
    return axios.get(`recommend/vid/list`, {params: data});
}

//获取关注用户的视频列表
export function careVideoList(data) {
    return axios.get(`follow/dynamics/list`, {params: data});
}

//查询视频详情
export function getVidInfo(data) {
    return axios.get(`vid/info`, {params: data});
}

// 查询我的模块-作品
export function queryCollection(data) {
    return axios.get(`mine/collection`, {params: data})
}

// 删除作品
export function removeVid(data) {
    return axios.post(`vid/remove`, data)
}

//视频购买接口
export function buyVideo(data) {
    return axios.post(`product/buy`, data);
}

//获取用户剩余观看次数
export function playCount(data) {
    return axios.get(`vid/user/count`, {params: data});
}

//打赏
export function reward(data) {
    return axios.post(`mine/reward`, data);
}

//视频播放调用接口
export function getPlayVideo(data) {
    return axios.post(`vid/play`, data);
}


//获取城市详情
export function queryLocationInfo(data) {
    return axios.get("vid/location/info", {params: data})
}

// 获取城市列表视频
export function queryLocationList(data) {
    return axios.get("vid/location/list", {params: data})
}
